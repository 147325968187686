import { createAsyncThunk } from '@reduxjs/toolkit';

import profileService from 'utils/request-services/ProfileService';

export const fetchGetProfileInfo = createAsyncThunk(
  'profile/fetchGetProfileInfo',
  async (_, { rejectWithValue }) => {
    try {
      return await profileService.getProfileInfo();
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
