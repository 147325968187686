import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFoundPage from 'utils/common-components/NotFoundPage';

const PublicRoutes: React.FC = () => (
  <Routes>
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default PublicRoutes;
