import React, { memo } from 'react';
import { Route, Routes } from 'react-router-dom';

import NotFoundPage from 'utils/common-components/NotFoundPage';

const TenantsLayout = React.lazy(() => import('./layout'));
const TenantDataWrapper = React.lazy(
  () => import('utils/wrappers/route-wrappers/TenantDataWrapper'),
);
const TenantLayoutWithBreadCrumbs = React.lazy(
  () => import('./components/TenantLayoutWithBreadCrumbs'),
);

const TenantHomePage = React.lazy(() => import('./TenantHomePage'));
const TenantsListPage = React.lazy(() => import('./TenantsListPage'));

const TenantFeatures = React.lazy(
  () => import('./tenant-specific-entities/tenant-features'),
);
const TenantLoansRoutes = React.lazy(
  () => import('./tenant-specific-entities/loans'),
);
const TenantGrantsRoutes = React.lazy(
  () => import('./tenant-specific-entities/grants'),
);
const TenantProgramsAndResources = React.lazy(
  () => import('./tenant-specific-entities/programsAndResources'),
);
const TenantDetails = React.lazy(
  () => import('./tenant-specific-entities/tenant-details'),
);
const TenantCopiesRoutes = React.lazy(
  () => import('./tenant-specific-entities/copies'),
);
const TenantOrganizationsRoutes = React.lazy(
  () => import('./tenant-specific-entities/organizations'),
);

const TenantRoutes: React.FC = memo(() => (
  <Routes>
    <Route element={<TenantsLayout />}>
      <Route index element={<TenantsListPage />} />

      <Route element={<TenantDataWrapper />}>
        <Route path="/:tenantId" element={<TenantHomePage />} />

        <Route element={<TenantLayoutWithBreadCrumbs />}>
          <Route path="/:tenantId/details/*" element={<TenantDetails />} />
          <Route path="/:tenantId/copies/*" element={<TenantCopiesRoutes />} />
          <Route
            path="/:tenantId/app-features/*"
            element={<TenantFeatures />}
          />
          <Route path="/:tenantId/loans/*" element={<TenantLoansRoutes />} />
          <Route path="/:tenantId/grants/*" element={<TenantGrantsRoutes />} />
          <Route
            path="/:tenantId/programs-and-resources/*"
            element={<TenantProgramsAndResources />}
          />
          <Route
            path="/:tenantId/organizations/*"
            element={<TenantOrganizationsRoutes />}
          />
        </Route>
      </Route>
    </Route>

    <Route path="*" element={<NotFoundPage />} />
  </Routes>
));

export default TenantRoutes;
