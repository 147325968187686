import { createSlice } from '@reduxjs/toolkit';
import { PageConfiguration } from '_types/tenant-page-configuration';

import type {
  Tenant,
  TenantFeature,
  TenantSettings,
  TenantStyleDetails,
} from '_types/tenant.types';

import {
  fetchGetAlltenants,
  fetchTenantSettings,
  fetchGetTenantData,
  fetchGetTenantFeature,
  fetchGetTenantFeatureList,
  fetchGetTenantPageConfigurations,
  fetchGetTenantPageConfigurationsList,
  fetchGetTenantStyling,
  updateGetTenantStyling,
  updateTenantFeature,
} from 'store/actions/tenant';

interface TenantState {
  tenants: {
    data: Tenant[] | null;
    isLoading: boolean;
  };
  tenantData: Tenant | null;
  isTenantDataLoading: boolean;
  tenantStyling: TenantStyleDetails | null;
  tenantSettings?: TenantSettings;
  isTenantStylingDataLoading: boolean;
  isTenantStylingUpdateLoading: boolean;
  pageConfigurations: {
    pageConfigurationsList: {
      data: PageConfiguration[] | null;
      isLoading: boolean;
    };
    pageConfiguration: {
      data: PageConfiguration | null;
      isLoading: boolean;
      isUploadLoading: boolean;
    };
  };
  features: {
    featureList: {
      data: TenantFeature[] | null;
      isLoading: boolean;
    };
    feature: {
      data: TenantFeature | null;
      isLoading: boolean;
      isUploadLoading: boolean;
    };
  };
}

const initPageConfData = {
  pageConfigurationsList: {
    data: null,
    isLoading: false,
  },
  pageConfiguration: {
    data: null,
    isLoading: false,
    isUploadLoading: false,
  },
};

const initFeatureData = {
  featureList: {
    data: null,
    isLoading: false,
  },
  feature: {
    data: null,
    isLoading: false,
    isUploadLoading: false,
  },
};

const tenantInitialState: TenantState = {
  tenants: {
    data: null,
    isLoading: false,
  },
  tenantData: null,
  isTenantDataLoading: true,
  tenantStyling: null,
  isTenantStylingDataLoading: false,
  isTenantStylingUpdateLoading: false,
  pageConfigurations: initPageConfData,
  features: initFeatureData,
};

const tenantSlice = createSlice({
  name: 'tenant',
  initialState: tenantInitialState,
  reducers: {
    resetState: (state) => ({ ...tenantInitialState, tenants: state.tenants }),
    resetStyleState: (state) => {
      state.tenantStyling = null;
      state.isTenantStylingDataLoading = false;
      state.isTenantStylingUpdateLoading = false;
    },
    resetpageConfiState: (state) => {
      state.pageConfigurations = initPageConfData;
    },
    resetFeatureState: (state) => {
      state.features = initFeatureData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetTenantData.pending, (state) => {
      state.isTenantDataLoading = true;
    });
    builder.addCase(fetchGetTenantData.fulfilled, (state, action) => {
      state.tenantData = action.payload;
      state.isTenantDataLoading = false;
    });
    builder.addCase(fetchGetTenantData.rejected, (state) => {
      state.isTenantDataLoading = false;
    });

    builder.addCase(fetchGetTenantStyling.pending, (state) => {
      state.isTenantStylingDataLoading = true;
    });
    builder.addCase(fetchGetTenantStyling.fulfilled, (state, action) => {
      state.tenantStyling = {
        ...state.tenantData,
        ...action.payload,
      } as TenantStyleDetails;
      state.isTenantStylingDataLoading = false;
    });
    builder.addCase(fetchGetTenantStyling.rejected, (state) => {
      state.isTenantStylingDataLoading = false;
    });

    builder.addCase(updateGetTenantStyling.pending, (state) => {
      state.isTenantStylingUpdateLoading = true;
    });
    builder.addCase(updateGetTenantStyling.fulfilled, (state, action) => {
      state.tenantStyling = {
        ...state.tenantData,
        ...action.payload,
      } as TenantStyleDetails;
      state.isTenantStylingUpdateLoading = false;
    });
    builder.addCase(updateGetTenantStyling.rejected, (state) => {
      state.isTenantStylingUpdateLoading = false;
    });

    builder.addCase(fetchGetTenantPageConfigurationsList.pending, (state) => {
      state.pageConfigurations.pageConfigurationsList.isLoading = true;
    });
    builder.addCase(
      fetchGetTenantPageConfigurationsList.fulfilled,
      (state, action) => {
        state.pageConfigurations.pageConfigurationsList.isLoading = false;
        state.pageConfigurations.pageConfigurationsList.data = action.payload;
      },
    );
    builder.addCase(fetchGetTenantPageConfigurationsList.rejected, (state) => {
      state.pageConfigurations.pageConfigurationsList.isLoading = false;
    });

    builder.addCase(fetchGetTenantPageConfigurations.pending, (state) => {
      state.pageConfigurations.pageConfiguration.isLoading = true;
    });
    builder.addCase(
      fetchGetTenantPageConfigurations.fulfilled,
      (state, action) => {
        state.pageConfigurations.pageConfiguration.isLoading = false;
        state.pageConfigurations.pageConfiguration.data = action.payload;
      },
    );
    builder.addCase(fetchGetTenantPageConfigurations.rejected, (state) => {
      state.pageConfigurations.pageConfiguration.isLoading = false;
    });

    builder.addCase(fetchGetTenantFeatureList.pending, (state) => {
      state.features.featureList.isLoading = true;
    });
    builder.addCase(fetchGetTenantFeatureList.fulfilled, (state, action) => {
      state.features.featureList.isLoading = false;
      state.features.featureList.data = action.payload;
    });
    builder.addCase(fetchGetTenantFeatureList.rejected, (state) => {
      state.features.featureList.isLoading = false;
    });

    builder.addCase(fetchGetTenantFeature.pending, (state) => {
      state.features.feature.isLoading = true;
    });
    builder.addCase(fetchGetTenantFeature.fulfilled, (state, action) => {
      state.features.feature.isLoading = false;
      state.features.feature.data = action.payload;
    });
    builder.addCase(fetchGetTenantFeature.rejected, (state) => {
      state.features.feature.isLoading = false;
    });

    builder.addCase(updateTenantFeature.pending, (state) => {
      state.features.feature.isUploadLoading = true;
    });
    builder.addCase(updateTenantFeature.fulfilled, (state, action) => {
      state.features.feature.isUploadLoading = false;
      state.features.feature.data = action.payload;
    });
    builder.addCase(updateTenantFeature.rejected, (state) => {
      state.features.feature.isUploadLoading = false;
    });

    builder.addCase(fetchGetAlltenants.pending, (state) => {
      state.tenants.isLoading = true;
    });
    builder.addCase(fetchGetAlltenants.fulfilled, (state, action) => {
      state.tenants.data = action.payload;
      state.tenants.isLoading = false;
    });
    builder.addCase(fetchGetAlltenants.rejected, (state) => {
      state.tenants.isLoading = false;
    });
    builder.addCase(fetchTenantSettings.pending, (state) => {
      state.isTenantDataLoading = true;
    });
    builder.addCase(fetchTenantSettings.fulfilled, (state, action) => {
      state.tenantSettings = action.payload;
      state.isTenantDataLoading = false;
    });
    builder.addCase(fetchTenantSettings.rejected, (state) => {
      state.isTenantDataLoading = false;
    });
  },
});

export const {
  resetState,
  resetStyleState,
  resetpageConfiState,
  resetFeatureState,
} = tenantSlice.actions;

export default tenantSlice;
