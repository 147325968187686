import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdvisoryOrgAdminInvitation } from '_types/advisory-organization.types';
import { store } from 'store/configureStore';
import AdvisoryOrgService from 'utils/request-services/AdvisoryOrgService';

export const fetchGetOrganizationsOnTenant = createAsyncThunk(
  'advisoryOrg/fetchGetOrganizationsOnTenant',
  async (_, { rejectWithValue }) => {
    try {
      const tenantId = store.getState().tenant.tenantData?.id as number;
      const organizations =
        await AdvisoryOrgService.getOrganizationsOnTenant(tenantId);
      return organizations;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchCreateAdvisoryOrgAdminInvitation = createAsyncThunk(
  'advisoryOrg/fetchCreateAdvisoryOrgAdminInvitation',
  async (invitationToSend: AdvisoryOrgAdminInvitation, { rejectWithValue }) => {
    try {
      const tenantDomain = store.getState().tenant.tenantData!.domain;
      const invitation =
        await AdvisoryOrgService.createAdvisoryOrgAdminInvitation(
          tenantDomain,
          invitationToSend,
        );
      return invitation;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
