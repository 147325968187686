import React, { memo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AuthenticationRequiredWrapper from 'utils/wrappers/route-wrappers/AuthenticationRequiredWrapper';

import AuthPagesRoutes from './auth/routes';
import TenantRoutes from './tenants/routes';
import PublicRoutes from './public/routes';
import ProfileRoutes from './profile/routes';

const GlobalRoutes: React.FC = memo(() => (
  <Routes>
    <Route index element={<Navigate to="/tenant/" />} />

    <Route path="/auth/*" element={<AuthPagesRoutes />} />

    <Route element={<AuthenticationRequiredWrapper />}>
      <Route path="/tenant/*" element={<TenantRoutes />} />
    </Route>

    <Route element={<AuthenticationRequiredWrapper />}>
      <Route path="/profile/*" element={<ProfileRoutes />} />
    </Route>

    <Route path="*" element={<PublicRoutes />} />
  </Routes>
));

export default GlobalRoutes;
