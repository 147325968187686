import { createAsyncThunk } from '@reduxjs/toolkit';
import { PartnerResourcesDto } from '_types/partner-resources-types';
import { store } from 'store/configureStore';
import PartnerResourcesService from 'utils/request-services/PartnerResourcesService';

export const fetchGetPartnerResourcesList = createAsyncThunk(
  'partnerResources/fetchGetPartnerResourcesList',
  async (_, { rejectWithValue }) => {
    try {
      const tenantId = store.getState().tenant.tenantData?.id as number;
      const partnerResources =
        await PartnerResourcesService.getPartnerResourcesList(tenantId);
      return partnerResources;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchGetPartnerResource = createAsyncThunk(
  'partnerResources/fetchGetPartnerResource',
  async (id: number, { rejectWithValue }) => {
    try {
      const partnerResources =
        await PartnerResourcesService.getPartnerResource(id);
      return partnerResources;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchUpdatePartnerResource = createAsyncThunk(
  'partnerResources/fetchUpdatePartnerResource',
  async (updateData: PartnerResourcesDto, { rejectWithValue }) => {
    try {
      const partnerResources =
        await PartnerResourcesService.updatePartnerResource(updateData);
      return partnerResources;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchCreatePartnerResource = createAsyncThunk(
  'partnerResources/fetchCreatePartnerResource',
  async (createData: PartnerResourcesDto, { rejectWithValue }) => {
    try {
      const partnerResources =
        await PartnerResourcesService.createPartnerResource(createData);
      return partnerResources;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchDeletePartnerResource = createAsyncThunk(
  'partnerResources/fetchDeletePartnerResource',
  async (id: number, { rejectWithValue }) => {
    try {
      const partnerResources =
        await PartnerResourcesService.deletePartnerResource(id);
      return partnerResources.id;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
