import {
  FundingProductTypeEnum,
  GrantType,
  LoanType,
} from '_types/funding.types';

import { axiosBackendApiInstance } from 'utils/axios';

type FundingProduct = LoanType | GrantType;

const FundingService = {
  getFundingProductList: (type: FundingProductTypeEnum) =>
    axiosBackendApiInstance
      .get<FundingProduct[]>(`/api/v1/fundings/all/${type}`)
      .then(({ data }) => data),
  getFundingProductDetails: (type: FundingProductTypeEnum, id: number) =>
    axiosBackendApiInstance
      .get<FundingProduct>(`/api/v1/fundings/${type}/${id}`)
      .then(({ data }) => data),
  updateFundingProduct: (
    type: FundingProductTypeEnum,
    id: number,
    fundingProduct: FundingProduct,
  ) =>
    axiosBackendApiInstance
      .patch<FundingProduct>(`/api/v1/fundings/${type}/${id}`, fundingProduct)
      .then(({ data }) => data),
  deleteFundingProduct: (type: FundingProductTypeEnum, id: number) =>
    axiosBackendApiInstance
      .delete<FundingProduct>(`/api/v1/fundings/${type}/${id}`)
      .then(({ data }) => data),
  createFundingProduct: (
    type: FundingProductTypeEnum,
    fundingProduct: Omit<FundingProduct, 'id'>,
  ) =>
    axiosBackendApiInstance
      .post<FundingProduct>(`/api/v1/fundings/${type}`, fundingProduct)
      .then(({ data }) => data),
};

export default FundingService;
