import React, { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFoundPage from 'utils/common-components/NotFoundPage';

const AuthLayout = React.lazy(() => import('./layout'));
const LoginPage = React.lazy(() => import('./LoginPage'));

const AuthPagesRoutes: React.FC = memo(() => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="/login" element={<LoginPage />} />
    </Route>

    <Route path="*" element={<NotFoundPage />} />
  </Routes>
));

export default AuthPagesRoutes;
