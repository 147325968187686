import { PartnerResourcesDto } from '_types/partner-resources-types';

import { axiosBackendApiInstance } from 'utils/axios';

const PartnerResourcesService = {
  getPartnerResourcesList: (tenantId: number) =>
    axiosBackendApiInstance
      .get<
        PartnerResourcesDto[]
      >(`/api/v1/partner-resources/fetch-partner-resources-by-tenant/${tenantId}`)
      .then(({ data }) => data),
  getPartnerResource: (id: number) =>
    axiosBackendApiInstance
      .get<PartnerResourcesDto>(`/api/v1/partner-resources/${id}`)
      .then(({ data }) => data),
  createPartnerResource: (createPartnerResourcesDto: PartnerResourcesDto) =>
    axiosBackendApiInstance
      .post<PartnerResourcesDto>(
        '/api/v1/partner-resources',
        createPartnerResourcesDto,
      )
      .then(({ data }) => data),
  updatePartnerResource: (updatePartnerResourcesDto: PartnerResourcesDto) =>
    axiosBackendApiInstance
      .patch<PartnerResourcesDto>(
        `/api/v1/partner-resources/${updatePartnerResourcesDto.id}`,
        updatePartnerResourcesDto,
      )
      .then(({ data }) => data),
  deletePartnerResource: (id: number) =>
    axiosBackendApiInstance
      .delete<PartnerResourcesDto>(`/api/v1/partner-resources/${id}`)
      .then(({ data }) => data),
};

export default PartnerResourcesService;
