import { createContext } from 'react';
import { GlobalAlertConf } from './types';

export interface GlobalAlertsContextInterface {
  showGlobalAlert: (conf: GlobalAlertConf) => void;
}

const GlobalAlertsContext = createContext<GlobalAlertsContextInterface>({
  showGlobalAlert: () => {},
});

export default GlobalAlertsContext;
