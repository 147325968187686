import { Dispatch, combineReducers } from '@reduxjs/toolkit';

import { InjectedReducersType } from '_types/injector-typings';

import profileSlice from './profile';
import fundingSlice from './funding';
import tenantSlice from './tenant';
import partnerResources from './partner-resources';
import advisoryOrgSlice from './advisory-org';
import cohortPrograms from './cohort-programs';

export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error

  return combineReducers({
    ...injectedReducers,
    profile: profileSlice.reducer,
    funding: fundingSlice.reducer,
    tenant: tenantSlice.reducer,
    partnerResources: partnerResources.reducer,
    advisoryOrg: advisoryOrgSlice.reducer,
    cohortPrograms: cohortPrograms.reducer,
  });
}

export const clearReducersDataOnLogOut = (dispatch: Dispatch) => {
  dispatch(profileSlice.actions.resetState());
  dispatch(fundingSlice.actions.resetState());
  dispatch(tenantSlice.actions.resetState());
  dispatch(partnerResources.actions.resetState());
  dispatch(advisoryOrgSlice.actions.resetState());
  dispatch(cohortPrograms.actions.resetState());
};
