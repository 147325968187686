import { EntityType } from 'utils/common-components/EntityManagementRoutesSet/types';
import { PartialPlatformLanguagesMap } from './platform.types';
import { TenantTypeEnum } from './tenant.types';

export enum FundingProductTypeEnum {
  LOAN = 'loan',
  GRANT = 'grant',
}

export enum FundingProductLocationType {
  NEIGHBORHOOD = 'NEIGHBORHOOD',
  REGIONAL = 'REGIONAL',
  NATIONAL = 'NATIONAL',
}

export enum FundingReferralType {
  WEBSITE = 'website',
  ONLINE_APPLICATION = 'onlineApplication',
  EMAIL = 'email',
}

export type TenantLocationEnum = TenantTypeEnum | 'global';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TenantLocationEnum = { ...TenantTypeEnum, GLOBAL: 'global' };

export enum LenderTypeEnum {
  BANK = 'Bank',
  COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION = 'Community Development Financial Institution',
  MINORITY_DEPOSITORY_INSTITUTION = 'Minority Depository Institution',
  CREDIT_UNION = 'Credit Union',
  ALTERNATIVE_LENDER = 'Alternative Lender',
  CROWDFUNDER = 'Crowdfunder',
  OTHERS = 'Other',
}

export enum LoanCommunitiesServedEnum {
  PEOPLECOLOR = 'Loan for People of Color',
  WOMEN = 'Loan for Women',
  LGBTQ = 'Loan for LGBTQ+',
  VETERAN = 'Loan for Veteran',
  LOWINCOME = 'Loan for Low Income',
  IMMIGRANTS = 'Loan for Immigrants',
  ALL = 'All',
}

export enum GrantCommunitiesServedEnum {
  PEOPLECOLOR = 'Grant for People of Color',
  WOMEN = 'Grant for Women',
  LGBTQ = 'Grant for LGBTQ+',
  VETERAN = 'Grant for Veteran',
  LOWINCOME = 'Grant for Low Income',
  IMMIGRANTS = 'Grant for Immigrants',
  ALL = 'All',
}

export enum GrantorTypeEnum {
  CORPORATION = 'Corporation',
  NON_PROFIT = 'Nonprofit',
  FOUNDATION = 'Foundation',
  GOVERNMENT = 'Government',
  OTHER = 'Other',
}

export interface LoanType extends EntityType {
  productLocation: FundingProductLocationType;
  state: string[];
  zipCodes: number[];
  loanName: PartialPlatformLanguagesMap<string>;
  lenderName: string;
  productType: PartialPlatformLanguagesMap<string>;
  minTerm: number;
  maxTerm: number;
  minSize: number;
  maxSize: number;
  minAPR: number;
  maxAPR: number;
  minRevenue: number;
  minYearsInBusiness: number;
  description: PartialPlatformLanguagesMap<string>;
  onlineApplicationURL: string;
  phone: string;
  email: string;
  minCreditScore?: number;
  maxCreditScore?: number;
  fees?: string;
  minTimeFunding?: number;
  maxTimeFunding?: number;
  guarantor?: PartialPlatformLanguagesMap<string>;
  collateral?: PartialPlatformLanguagesMap<string>;
  deferment?: PartialPlatformLanguagesMap<string>;
  capInjection?: PartialPlatformLanguagesMap<string>;
  maxRevenue?: number;
  targetAudience?: PartialPlatformLanguagesMap<string>;
  keyDifferentiator?: PartialPlatformLanguagesMap<string[]>;
  nonProfit: boolean;
  referralMethod: FundingReferralType;
  communityServed?: LoanCommunitiesServedEnum[];
  fundingProviderIcon?: string;
  websiteURL?: string;
  lenderType: LenderTypeEnum;
  tenantLocation: TenantLocationEnum[];
}

export interface GrantType extends EntityType {
  grantName: PartialPlatformLanguagesMap<string>;
  grantorName: string;
  productLocation: FundingProductLocationType;
  state: string[];
  zipCodes: number[];
  maxGrantSize: number;
  eligibilityBusinessType: PartialPlatformLanguagesMap<string>;
  eligibilityBusinessSize: number;
  eligibilityRevenueMin: number;
  eligibilityRevenueMax: number;
  eligibilityStartDate: string | null;
  eligibilityGeography: PartialPlatformLanguagesMap<string[]>;
  useOfGrant: PartialPlatformLanguagesMap<string>;
  startApplication: string | null;
  applicationDeadline: string | null;
  onlineApplicationURL: string;
  tenantDomain: string;
  minGrantSize: number;
  minYearsInBusiness: number;
  minBusinessEmployees: number;
  maxBusinessEmployees: number;
  applicationFee: number;
  sector: PartialPlatformLanguagesMap<string>;
  eligibility: PartialPlatformLanguagesMap<string[]>;
  referralMethod: string;
  communityServed: GrantCommunitiesServedEnum[];
  description: PartialPlatformLanguagesMap<string>;
  email: string;
  fundingProviderIcon: string;
  websiteURL: string;
  grantorType: GrantorTypeEnum;
  tenantLocation: TenantLocationEnum[];
}
