import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdatePageConfiguration } from '_types/tenant-page-configuration';
import { TenantFeature, TenantStyle } from '_types/tenant.types';

import TenantService from 'utils/request-services/TenantService';

export const fetchGetAlltenants = createAsyncThunk(
  'tenant/fetchGetAlltenants',
  async (_, { rejectWithValue }) => {
    try {
      const tenantData = await TenantService.getAllTenants();

      return tenantData;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchTenantSettings = createAsyncThunk(
  'tenant/fetchTenantSettings',
  async (tenantId: number, { rejectWithValue }) => {
    try {
      const tenantData = await TenantService.getTenantSettings(tenantId);

      return tenantData;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchGetTenantData = createAsyncThunk(
  'tenant/fetchGetTenantData',
  async (tenantId: number, { rejectWithValue }) => {
    try {
      const tenantData = await TenantService.getTenantData(tenantId);

      return tenantData;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchGetTenantStyling = createAsyncThunk(
  'tenant/fetchGetTenantStyling',
  async (tenantId: number, { rejectWithValue }) => {
    try {
      const tenantStyling = await TenantService.getTenantStyling(tenantId);
      return tenantStyling;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateGetTenantStyling = createAsyncThunk(
  'tenant/updateGetTenantStyling',
  async (updatedTenantStyle: TenantStyle, { rejectWithValue }) => {
    try {
      const tenantData =
        await TenantService.updateTenantStyling(updatedTenantStyle);
      return tenantData;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchGetTenantPageConfigurationsList = createAsyncThunk(
  'tenant/fetchGetTenantPageConfigurationsList',
  async (_types, { rejectWithValue }) => {
    try {
      const tenantPageConfigurations =
        await TenantService.getTenantPageConfigurationsList();
      return tenantPageConfigurations;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchGetTenantPageConfigurations = createAsyncThunk(
  'tenant/fetchGetTenantPageConfigurations',
  async (id: number, { rejectWithValue }) => {
    try {
      const tenantPageConfigurations =
        await TenantService.getTenantPageConfigurations(id);
      return tenantPageConfigurations;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateTenantPageConfigurations = createAsyncThunk(
  'tenant/updateTenantPageConfigurations',
  async (
    updatedTenantPageConfig: UpdatePageConfiguration,
    { rejectWithValue },
  ) => {
    try {
      const tenantPageConfigurations =
        await TenantService.updateTenantPageConfiguration(
          updatedTenantPageConfig,
        );
      return tenantPageConfigurations;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchGetTenantFeatureList = createAsyncThunk(
  'tenant/fetchGetTenantFeatureList',
  async (_types, { rejectWithValue }) => {
    try {
      const tenantfeatures = await TenantService.getTenantFeatureList();
      return tenantfeatures;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchGetTenantFeature = createAsyncThunk(
  'tenant/fetchGetTenantFeature',
  async (id: number, { rejectWithValue }) => {
    try {
      const tenantfeature = await TenantService.getTenantFeature(id);
      return tenantfeature;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateTenantFeature = createAsyncThunk(
  'tenant/updateTenantFeature',
  async (updatedTenantPageConfig: TenantFeature, { rejectWithValue }) => {
    try {
      const tenantfeature = await TenantService.updateTenantFeature(
        updatedTenantPageConfig,
      );
      return tenantfeature;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createTenantFeature = createAsyncThunk(
  'tenant/createTenantFeature',
  async (tenantFeature: TenantFeature, { rejectWithValue }) => {
    try {
      const tenantfeature =
        await TenantService.createTenantFeature(tenantFeature);
      return tenantfeature;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
