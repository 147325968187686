import {
  AdvisoryOrgAdminInvitation,
  AdvisoryOrganizationList,
} from '_types/advisory-organization.types';
import { axiosBackendApiInstance } from 'utils/axios';

const AdvisoryOrgService = {
  getOrganizationsOnTenant: (tenantId: number) =>
    axiosBackendApiInstance
      .get<
        AdvisoryOrganizationList[]
      >(`/api/v1/advisory-org/list-on-tenant/${tenantId}`)
      .then(({ data }) => data),

  createAdvisoryOrgAdminInvitation: (
    tenantDomain: string,
    invitation: AdvisoryOrgAdminInvitation,
  ) =>
    axiosBackendApiInstance
      .post<AdvisoryOrgAdminInvitation>(
        `/api/v1/advisory-org/invite/`,
        invitation,
        { headers: { Origin: tenantDomain } },
      )
      .then(({ data }) => data),
};

export default AdvisoryOrgService;
