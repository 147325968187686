import { createAsyncThunk } from '@reduxjs/toolkit';
import { CohortProgramsDto } from '_types/cohort-programs-types';
import CohortProgramsService from 'utils/request-services/cohortProgramsService';

export const fetchGetAllCohortProgramsList = createAsyncThunk(
  'cohortPrograms/fetchGetAllCohortProgramsList',
  async (_, { rejectWithValue }) => {
    try {
      const cohortPrograms =
        await CohortProgramsService.getAllCohortProgramsList();
      return cohortPrograms;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchGetCohortProgram = createAsyncThunk(
  'cohortPrograms/fetchGetCohortPrograms',
  async (id: number, { rejectWithValue }) => {
    try {
      const cohortPrograms = await CohortProgramsService.getCohortProgram(id);
      return cohortPrograms;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchUpdateCohortPrograms = createAsyncThunk(
  'cohortPrograms/fetchUpdateCohortPrograms',
  async (updateData: CohortProgramsDto, { rejectWithValue }) => {
    try {
      const cohortPrograms =
        await CohortProgramsService.updateCohortProgram(updateData);
      return cohortPrograms;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchCreateCohortPrograms = createAsyncThunk(
  'cohortPrograms/fetchCreateCohortPrograms',
  async (createData: CohortProgramsDto, { rejectWithValue }) => {
    try {
      const cohortPrograms =
        await CohortProgramsService.createCohortProgram(createData);
      return cohortPrograms;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchDeleteCohortPrograms = createAsyncThunk(
  'cohortPrograms/fetchDeleteCohortPrograms',
  async (id: number, { rejectWithValue }) => {
    try {
      const cohortPrograms =
        await CohortProgramsService.deleteCohortProgram(id);

      return cohortPrograms.id;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
