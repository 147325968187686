import { CohortProgramsDto } from '_types/cohort-programs-types';

import { axiosBackendApiInstance } from 'utils/axios';

const CohortProgramsService = {
  getAllCohortProgramsList: () =>
    axiosBackendApiInstance
      .get<CohortProgramsDto[]>(`/api/v1/cohort-program`)
      .then(({ data }) => data),
  getCohortProgram: (id: number) =>
    axiosBackendApiInstance
      .get<CohortProgramsDto>(`/api/v1/cohort-program/${id}`)
      .then(({ data }) => data),
  createCohortProgram: (createCohortProgramsDto: CohortProgramsDto) =>
    axiosBackendApiInstance
      .post<CohortProgramsDto>(
        '/api/v1/cohort-program',
        createCohortProgramsDto,
      )
      .then(({ data }) => data),
  updateCohortProgram: (updateCohortProgramsDto: CohortProgramsDto) =>
    axiosBackendApiInstance
      .patch<CohortProgramsDto>(
        `/api/v1/cohort-program/${updateCohortProgramsDto.id}`,
        updateCohortProgramsDto,
      )
      .then(({ data }) => data),
  deleteCohortProgram: (id: number) =>
    axiosBackendApiInstance
      .delete<CohortProgramsDto>(`/api/v1/cohort-program/${id}`)
      .then(({ data }) => data),
};

export default CohortProgramsService;
