import React, { memo, useEffect } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { fetchGetProfileInfo } from 'store/actions/profile';
import { fetchGetAlltenants } from 'store/actions/tenant';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { isProfileInfoPresentSelector } from 'store/selectors/profile';

import { useCurrentAccessToken } from 'utils/authentication/hooks';
import Loader from 'utils/common-components/Loader';

const AuthenticationRequiredWrapper: React.FC = memo(() => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentAccessToken = useCurrentAccessToken();
  const isProfileInfoPresent = useAppSelector(isProfileInfoPresentSelector);

  useEffect(() => {
    (async () => {
      try {
        if (currentAccessToken && !isProfileInfoPresent) {
          await dispatch(fetchGetProfileInfo()).unwrap();
          await dispatch(fetchGetAlltenants());
        }
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert('An error occurred while fetching profile info');
      }
    })();
  }, [currentAccessToken, isProfileInfoPresent]);

  if (!currentAccessToken) {
    const encodedUrl = encodeURIComponent(
      `${location.pathname}${location.search}`,
    );
    return <Navigate to={`/auth/login?next=${encodedUrl}`} />;
  }

  if (!isProfileInfoPresent) {
    return <Loader />;
  }

  return <Outlet />;
});

export default AuthenticationRequiredWrapper;
