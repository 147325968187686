import axios from 'axios';

import {
  getAccessAuthToken,
  reloadCurrentFirebaseUser,
} from './authentication/utils';

// import { newRelic } from 'utils/newRelic';

const baseURL = process.env.REACT_APP_BACKEND_DOMAIN;

type AxiosAdditionalGlobalConfiguration = {
  tenantOrigin: string | null;
};

export const axiosAdditionalGlobalConfiguration: AxiosAdditionalGlobalConfiguration =
  {
    tenantOrigin: null,
  };

export const axiosBackendApiInstance = axios.create({ baseURL });

const ADMIN_AUTH_TOKEN_HEADER = 'x-auth-admin';

// Request interceptor for API calls
axiosBackendApiInstance.interceptors.request.use(
  async (config) => {
    /* eslint-disable no-param-reassign */
    config.headers[ADMIN_AUTH_TOKEN_HEADER] ||= await getAccessAuthToken(true);
    config.headers.Accept ||= 'application/json';
    config.headers['Content-Type'] ||= 'application/json';
    /* eslint-enable no-param-reassign */

    const { tenantOrigin } = axiosAdditionalGlobalConfiguration;
    if (tenantOrigin) {
      config.headers['x-implicit-tenant-origin'] = tenantOrigin;
    }

    return config;
  },
  (error) => Promise.reject(error),
);
axiosBackendApiInstance.interceptors.response.use(
  (response) =>
    // newRelic.addPageAction('API-Event-Succeed', { ...response });
    response,
  async (error) => {
    // newRelic.addPageAction('API-Event-Failed', { ...error });
    if (error?.response?.status === 401) {
      reloadCurrentFirebaseUser();
    }

    return Promise.reject(error);
  },
);
