import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import GlobalErrorBoundary from 'utils/wrappers/app-wrappers/GlobalErrorWrapper';
import RecaptchaContainerContextProvider from 'utils/contexts/RecaptchaContainerContext/RecaptchaContainerContextProvider';
import FirebaseAuthDataContextProvider from 'utils/contexts/FirebaseAuthDataContext/FirebaseAuthDataContextProvider';
import Loader from 'utils/common-components/Loader';
import GlobalAlertsContextProvider from 'utils/contexts/GlobalAlertsContext/GlobalAlertsContextProvider';

import { store } from 'store/configureStore';

import GlobalRoutes from 'modules/global-routes';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import reportWebVitals from './reportWebVitals';

import './styles/app.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <HelmetProvider>
        <BrowserRouter>
          <GlobalErrorBoundary>
            <GlobalAlertsContextProvider>
              <RecaptchaContainerContextProvider>
                <FirebaseAuthDataContextProvider>
                  <Suspense fallback={<Loader />}>
                    <GlobalRoutes />
                  </Suspense>
                </FirebaseAuthDataContextProvider>
              </RecaptchaContainerContextProvider>
            </GlobalAlertsContextProvider>
          </GlobalErrorBoundary>
        </BrowserRouter>
      </HelmetProvider>
    </LocalizationProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
