import { createSlice } from '@reduxjs/toolkit';
import {
  AdvisoryOrgAdminInvitation,
  AdvisoryOrganizationList,
} from '_types/advisory-organization.types';
import {
  fetchCreateAdvisoryOrgAdminInvitation,
  fetchGetOrganizationsOnTenant,
} from 'store/actions/advisory-org';

type AdvisoryOrgState = {
  orgList: AdvisoryOrganizationList[] | null;
  isOrgListLoading: boolean;
  isOrgActionInProgress: boolean;
  invitation: AdvisoryOrgAdminInvitation | null;
  isInvitationLoading: boolean;
  isInvitationActionInProgress: boolean;
};

const advisoryOrgInitialState: AdvisoryOrgState = {
  orgList: null,
  isOrgListLoading: true,
  isOrgActionInProgress: false,
  invitation: null,
  isInvitationLoading: true,
  isInvitationActionInProgress: false,
};

const advisoryOrgSlice = createSlice({
  name: 'advisoryOrg',
  initialState: advisoryOrgInitialState,
  reducers: {
    resetState: () => advisoryOrgInitialState,
  },
  extraReducers: (builder) => {
    // Orgnizations
    builder.addCase(fetchGetOrganizationsOnTenant.pending, (state) => {
      state.isOrgListLoading = true;
    });
    builder.addCase(
      fetchGetOrganizationsOnTenant.fulfilled,
      (state, action) => {
        state.orgList = action.payload;
        state.isOrgListLoading = false;
      },
    );
    builder.addCase(fetchGetOrganizationsOnTenant.rejected, (state) => {
      state.isOrgListLoading = false;
    });
    // Invitation
    builder.addCase(fetchCreateAdvisoryOrgAdminInvitation.pending, (state) => {
      state.isInvitationActionInProgress = true;
    });
    builder.addCase(
      fetchCreateAdvisoryOrgAdminInvitation.fulfilled,
      (state, action) => {
        state.invitation = action.payload;
        state.isInvitationActionInProgress = false;
      },
    );
    builder.addCase(fetchCreateAdvisoryOrgAdminInvitation.rejected, (state) => {
      state.isInvitationActionInProgress = false;
    });
  },
});

export const { resetState } = advisoryOrgSlice.actions;

export default advisoryOrgSlice;
