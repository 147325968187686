import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  FundingProductTypeEnum,
  GrantType,
  LoanType,
} from '_types/funding.types';

import FundingService from 'utils/request-services/FundingService';

export const fetchGetTenantLoanList = createAsyncThunk(
  'funding/fetchGetTenantLoanList',
  async (_, { rejectWithValue }) => {
    try {
      const loanList = (await FundingService.getFundingProductList(
        FundingProductTypeEnum.LOAN,
      )) as LoanType[];

      return loanList;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchGetLoan = createAsyncThunk(
  'funding/fetchGetLoan',
  async (id: number, { rejectWithValue }) => {
    try {
      const loan = (await FundingService.getFundingProductDetails(
        FundingProductTypeEnum.LOAN,
        id,
      )) as LoanType;

      return loan;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchCreateLoan = createAsyncThunk(
  'funding/fetchCreateLoan',
  async (data: Omit<LoanType, 'id'>, { rejectWithValue }) => {
    try {
      const createdLoan = (await FundingService.createFundingProduct(
        FundingProductTypeEnum.LOAN,
        data,
      )) as LoanType;

      return createdLoan;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchUpdateLoan = createAsyncThunk(
  'funding/fetchUpdateLoan',
  async (data: LoanType, { rejectWithValue }) => {
    try {
      const updatedLoan = (await FundingService.updateFundingProduct(
        FundingProductTypeEnum.LOAN,
        data.id as number,
        data,
      )) as LoanType;

      return updatedLoan;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchDeleteLoan = createAsyncThunk(
  'funding/fetchDeleteLoan',
  async (id: number, { rejectWithValue }) => {
    try {
      await FundingService.deleteFundingProduct(
        FundingProductTypeEnum.LOAN,
        id,
      );

      return id;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

// same for grants

export const fetchGetTenantGrantList = createAsyncThunk(
  'funding/fetchGetTenantGrantList',
  async (_, { rejectWithValue }) => {
    try {
      const grantList = (await FundingService.getFundingProductList(
        FundingProductTypeEnum.GRANT,
      )) as GrantType[];

      return grantList;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchGetGrant = createAsyncThunk(
  'funding/fetchGetGrant',
  async (id: number, { rejectWithValue }) => {
    try {
      const grant = (await FundingService.getFundingProductDetails(
        FundingProductTypeEnum.GRANT,
        id,
      )) as GrantType;

      return grant;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchCreateGrant = createAsyncThunk(
  'funding/fetchCreateGrant',
  async (data: Omit<GrantType, 'id'>, { rejectWithValue }) => {
    try {
      const createdGrant = (await FundingService.createFundingProduct(
        FundingProductTypeEnum.GRANT,
        data,
      )) as GrantType;

      return createdGrant;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchUpdateGrant = createAsyncThunk(
  'funding/fetchUpdateGrant',
  async (data: GrantType, { rejectWithValue }) => {
    try {
      const updatedGrant = (await FundingService.updateFundingProduct(
        FundingProductTypeEnum.GRANT,
        data.id as number,
        data,
      )) as GrantType;

      return updatedGrant;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchDeleteGrant = createAsyncThunk(
  'funding/fetchDeleteGrant',
  async (id: number, { rejectWithValue }) => {
    try {
      await FundingService.deleteFundingProduct(
        FundingProductTypeEnum.GRANT,
        id,
      );

      return id;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
