import React, { ReactNode, memo, useMemo, useRef } from 'react';
import { RecaptchaVerifier } from '@firebase/auth';

import RecaptchaContainerContext, { RecaptchaContextInterface } from '.';

interface RecaptchaContainerContextProps {
  children: ReactNode;
}

const RecaptchaContainerContextProvider: React.FC<RecaptchaContainerContextProps> =
  memo(({ children }) => {
    const recaptchaContainerRef = useRef<HTMLDivElement>(null);
    const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);

    const contextValue = useMemo(
      () =>
        ({
          recaptchaContainerRef,
          recaptchaVerifierRef,
        }) as RecaptchaContextInterface,
      [],
    );

    return (
      <RecaptchaContainerContext.Provider value={contextValue}>
        {children}

        <div ref={recaptchaContainerRef}>
          <div id="recaptcha-container-id" />
        </div>
      </RecaptchaContainerContext.Provider>
    );
  });

export default RecaptchaContainerContextProvider;
