import {
  PageConfiguration,
  UpdatePageConfiguration,
} from '_types/tenant-page-configuration';
import {
  Tenant,
  TenantFeature,
  TenantSettings,
  TenantStyle,
} from '_types/tenant.types';

import { axiosBackendApiInstance } from 'utils/axios';

const TenantService = {
  getAllTenants: () =>
    axiosBackendApiInstance
      .get<Tenant[]>('/api/v1/tenants')
      .then(({ data }) => data),
  getTenantData: (tenantId: number) =>
    axiosBackendApiInstance
      .get<Tenant>(`/api/v1/tenants/${tenantId}`)
      .then(({ data }) => data),
  getTenantStyling: (tenantId: number) =>
    axiosBackendApiInstance
      .get<TenantStyle>(`/api/v1/tenant-settings/tenant-styling/${tenantId}`)
      .then(({ data }) => data),
  getTenantSettings: (tenantId: number) =>
    axiosBackendApiInstance
      .get<TenantSettings>(`/api/v1/tenant-settings/${tenantId}`)
      .then(({ data }) => data),
  updateTenantStyling: (tenantData: TenantStyle) =>
    axiosBackendApiInstance
      .patch<TenantStyle>(
        `/api/v1/tenant-settings/tenant-styling/${tenantData.id}`,
        tenantData,
      )
      .then(({ data }) => data),
  getTenantPageConfigurations: (id: number) =>
    axiosBackendApiInstance
      .get<PageConfiguration>(
        `/api/v1/tenant-settings/tenant-page-configuration/single/${id}`,
      )
      .then(({ data }) => data),
  getTenantPageConfigurationsList: () =>
    axiosBackendApiInstance
      .get<
        PageConfiguration[]
      >(`/api/v1/tenant-settings/tenant-page-configuration`)
      .then(({ data }) => data),
  updateTenantPageConfiguration: (configData: UpdatePageConfiguration) =>
    axiosBackendApiInstance
      .patch<PageConfiguration>(
        `/api/v1/tenant-settings/page-configuration/edit/${configData.id}`,
        configData,
      )
      .then(({ data }) => data),
  getTenantFeature: (id: number) =>
    axiosBackendApiInstance
      .get<TenantFeature>(`/api/v1/tenant-feature/${id}`)
      .then(({ data }) => data),
  getTenantFeatureList: () =>
    axiosBackendApiInstance
      .get<TenantFeature[]>(`/api/v1/tenant-feature`)
      .then(({ data }) => data),
  createTenantFeature: (featureData: TenantFeature) =>
    axiosBackendApiInstance
      .post<TenantFeature>(`/api/v1/tenant-feature`, featureData)
      .then(({ data }) => data),
  updateTenantFeature: (featureData: TenantFeature) =>
    axiosBackendApiInstance
      .patch<TenantFeature>(
        `/api/v1/tenant-feature/${featureData.id}`,
        featureData,
      )
      .then(({ data }) => data),
};

export default TenantService;
