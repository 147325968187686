import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../Button';

interface NotFoundPageProps {}

const NotFoundPage: React.FC<NotFoundPageProps> = memo(() => {
  const navigate = useNavigate();

  const onGoBackHomeClick = useCallback(() => navigate('/'), [navigate]);

  return (
    <div>
      <p>Not Found</p>

      <Button look="filled" onClick={onGoBackHomeClick}>
        Go back home
      </Button>
    </div>
  );
});

export default NotFoundPage;
