import { RootState } from 'store/configureStore';

export const profileInfoSelector = (state: RootState) =>
  state.profile.profileInfo;
export const profileIdSelector = (state: RootState) =>
  state.profile.profileInfo?.id;
export const isProfileInfoPresentSelector = (state: RootState) =>
  !!state.profile.profileInfo;
export const isProfileInfoLoadingSelector = (state: RootState) =>
  state.profile.isProfileInfoLoading;
