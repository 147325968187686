import clsx from 'clsx';
import React, { memo, useMemo } from 'react';

import './style.scss';

interface LoaderProps {
  withContainer?: boolean;
  containerType?: 'popover-centered' | 'inline-block';
  containerClassName?: string;
  withText?: boolean;
  text?: string;
  color?: 'black' | 'white' | 'main-gray' | 'main-blue';
  size?: 'small' | 'normal' | 'large';
}

const Loader: React.FC<LoaderProps> = ({
  withContainer = true,
  containerType = 'popover-centered',
  containerClassName = '',
  withText = true,
  text = 'Loading...',
  color = 'black',
  size = 'large',
}) => {
  const loaderInnerContent = useMemo(
    () => (
      <>
        <div className={clsx('loader', color, size)}>
          <div className="loader-dot" />
          <div className="loader-dot" />
          <div className="loader-dot" />
          <div className="loader-dot" />
          <div className="loader-dot" />
          <div className="loader-dot" />
        </div>
        {withText && <p className="loader-text">{text}</p>}
      </>
    ),
    [color, size, withText, text],
  );

  if (!withContainer) {
    return loaderInnerContent;
  }

  return (
    <div
      id="container"
      className={clsx('loader_container', containerType, containerClassName)}
    >
      {loaderInnerContent}
    </div>
  );
};

export default memo(Loader);
