import { createSlice } from '@reduxjs/toolkit';
import { CohortProgramsDto } from '_types/cohort-programs-types';

import {
  fetchCreateCohortPrograms,
  fetchDeleteCohortPrograms,
  fetchGetAllCohortProgramsList,
  fetchGetCohortProgram,
  fetchUpdateCohortPrograms,
} from 'store/actions/cohort-programs';

interface CohortProgramsState {
  cohortPrograms: {
    data: CohortProgramsDto | null;
    isLoading: boolean;
    isUploading: boolean;
  };
  cohortProgramsList: {
    data: CohortProgramsDto[] | null;
    isLoading: boolean;
  };
}

const cohortProgramsInitialState: CohortProgramsState = {
  cohortPrograms: {
    data: null,
    isLoading: false,
    isUploading: false,
  },
  cohortProgramsList: {
    data: null,
    isLoading: false,
  },
};

const cohortProgramsSlice = createSlice({
  name: 'cohortPrograms',
  initialState: cohortProgramsInitialState,
  reducers: {
    resetState: () => cohortProgramsInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetAllCohortProgramsList.pending, (state) => {
      state.cohortProgramsList.isLoading = true;
    });
    builder.addCase(
      fetchGetAllCohortProgramsList.fulfilled,
      (state, action) => {
        state.cohortProgramsList.data = action.payload;
        state.cohortProgramsList.isLoading = false;
      },
    );
    builder.addCase(fetchGetAllCohortProgramsList.rejected, (state) => {
      state.cohortProgramsList.isLoading = false;
    });

    builder.addCase(fetchGetCohortProgram.pending, (state) => {
      state.cohortPrograms.isLoading = true;
    });
    builder.addCase(fetchGetCohortProgram.fulfilled, (state, action) => {
      state.cohortPrograms.data = action.payload;
      state.cohortPrograms.isLoading = false;
    });
    builder.addCase(fetchGetCohortProgram.rejected, (state) => {
      state.cohortPrograms.isLoading = false;
    });

    builder.addCase(fetchCreateCohortPrograms.pending, (state) => {
      state.cohortPrograms.isUploading = true;
    });
    builder.addCase(fetchCreateCohortPrograms.fulfilled, (state, action) => {
      state.cohortPrograms.data = action.payload;
      state.cohortPrograms.isUploading = false;
    });
    builder.addCase(fetchCreateCohortPrograms.rejected, (state) => {
      state.cohortPrograms.isUploading = false;
    });

    builder.addCase(fetchUpdateCohortPrograms.pending, (state) => {
      state.cohortPrograms.isUploading = true;
    });
    builder.addCase(fetchUpdateCohortPrograms.fulfilled, (state, action) => {
      state.cohortPrograms.data = action.payload;
      state.cohortPrograms.isUploading = false;
    });
    builder.addCase(fetchUpdateCohortPrograms.rejected, (state) => {
      state.cohortPrograms.isUploading = false;
    });

    builder.addCase(fetchDeleteCohortPrograms.pending, (state) => {
      state.cohortPrograms.isLoading = true;
    });
    builder.addCase(fetchDeleteCohortPrograms.fulfilled, (state, action) => {
      state.cohortPrograms.isLoading = false;
      state.cohortProgramsList.data = (
        state.cohortProgramsList.data || []
      ).filter((pr) => pr.id !== action.payload);
    });
    builder.addCase(fetchDeleteCohortPrograms.rejected, (state) => {
      state.cohortPrograms.isLoading = false;
    });
  },
});

export const { resetState } = cohortProgramsSlice.actions;

export default cohortProgramsSlice;
