import React, { ReactNode, memo, useCallback, useMemo, useState } from 'react';
import { uniqueId } from 'lodash';
import { Alert } from '@mui/material';
import clsx from 'clsx';

import GlobalAlertsContext, { GlobalAlertsContextInterface } from '.';
import { GlobalAlertConf } from './types';

import './style.scss';

interface GlobalAlertsContextProps {
  children: ReactNode;
}

type GlobalAlertConfWithId = GlobalAlertConf & { id: string };

const GlobalAlertsContextProvider: React.FC<GlobalAlertsContextProps> = memo(
  ({ children }) => {
    const [alerts, setAlerts] = useState<GlobalAlertConfWithId[]>([]);

    const showGlobalAlert = useCallback(
      (conf: GlobalAlertConf) => {
        const id = uniqueId();
        const confWithId: GlobalAlertConfWithId = { ...conf, id };

        setAlerts((alertsList) => [...alertsList, confWithId]);

        setTimeout(
          () =>
            setAlerts((alertsList) =>
              alertsList.filter((alert) => alert.id !== id),
            ),
          conf.time ?? 5000,
        );
      },
      [setAlerts],
    );

    const contextValue = useMemo(
      () => ({ showGlobalAlert }) as GlobalAlertsContextInterface,
      [],
    );

    return (
      <GlobalAlertsContext.Provider value={contextValue}>
        <div
          className={clsx('global-alerts-wrapper', { hidden: !alerts.length })}
        >
          {alerts.map((alertConf) => (
            <div className="global-alert">
              <Alert severity={alertConf.severity}>{alertConf.message}</Alert>
            </div>
          ))}
        </div>

        {children}
      </GlobalAlertsContext.Provider>
    );
  },
);

export default GlobalAlertsContextProvider;
