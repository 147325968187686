import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProfileInfo } from '_types/profile.types';

import { fetchGetProfileInfo } from 'store/actions/profile';

interface ProfileState {
  profileInfo: ProfileInfo | null;
  isProfileInfoLoading: boolean;
}

const profileInitialState: ProfileState = {
  profileInfo: null,
  isProfileInfoLoading: true,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState: profileInitialState,
  reducers: {
    resetState: () => profileInitialState,
    actionSetProfileInfo: (
      state,
      action: PayloadAction<ProfileInfo | null>,
    ) => {
      state.profileInfo = action.payload;
    },
    actionEditProfileInfo: (
      state,
      action: PayloadAction<Partial<ProfileInfo>>,
    ) => {
      state.profileInfo = {
        ...state.profileInfo!,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetProfileInfo.pending, (state) => {
      state.isProfileInfoLoading = true;
    });
    builder.addCase(fetchGetProfileInfo.fulfilled, (state, action) => {
      state.profileInfo = action.payload;
      state.isProfileInfoLoading = false;
    });
    builder.addCase(fetchGetProfileInfo.rejected, (state) => {
      state.isProfileInfoLoading = false;
    });
  },
});

export const { actionSetProfileInfo, actionEditProfileInfo } =
  profileSlice.actions;

export default profileSlice;
