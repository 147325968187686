import { User, multiFactor, signInWithEmailAndPassword } from '@firebase/auth';
import { store } from 'store/configureStore';
import { clearReducersDataOnLogOut } from 'store/reducers/root';
import { auth } from './firebase';

export const getFirebaseUserMFAPhoneNumber = (
  firebaseUser: User | null,
): string | null => {
  if (!firebaseUser) {
    return null;
  }

  const enrolledFactor: any = multiFactor(firebaseUser)?.enrolledFactors?.[0];

  return enrolledFactor?.phoneNumber || null;
};

export const getAccessAuthToken = async (
  withBearerWord: boolean = false,
): Promise<string> => {
  const accessToken = await auth.currentUser?.getIdToken();

  if (!accessToken) {
    return '';
  }

  if (withBearerWord) {
    return `Bearer ${accessToken}`;
  }

  return accessToken;
};

export const signInCurrentUserWithPassword = async (password: string) => {
  const user = auth.currentUser;
  if (user?.email) {
    await signInWithEmailAndPassword(auth, user.email, password);
  }
};

export const reloadCurrentFirebaseUser = async () => {
  await auth?.currentUser?.reload();
};

export const signOutCurrentFirebaseUser = async () => {
  clearReducersDataOnLogOut(store.dispatch);
  await auth?.signOut();
};
