import { ChangePasswordFormValues, ProfileInfo } from '_types/profile.types';

import { axiosBackendApiInstance } from 'utils/axios';

const ProfileService = {
  getProfileInfo: () =>
    axiosBackendApiInstance
      .get<ProfileInfo>('/api/v1/admin/me')
      .then(({ data }) => data),
  changePassowrd: (payload: ChangePasswordFormValues) =>
    axiosBackendApiInstance
      .post<ProfileInfo>('/api/v1/admin/change-password', payload)
      .then(({ data }) => data),
};

export default ProfileService;
