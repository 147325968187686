import { createSlice } from '@reduxjs/toolkit';
import { GrantType, LoanType } from '_types/funding.types';
import {
  fetchGetTenantLoanList,
  fetchCreateLoan,
  fetchDeleteLoan,
  fetchUpdateLoan,
  fetchGetLoan,
  fetchGetTenantGrantList,
  fetchGetGrant,
  fetchCreateGrant,
  fetchDeleteGrant,
  fetchUpdateGrant,
} from 'store/actions/funding';

type LoanState = {
  loanList: LoanType[] | null;
  isLoanListLoading: boolean;
  loan: LoanType | null;
  isLoanLoading: boolean;
  isLoanActionInProgress: boolean;
};

type GrantState = {
  grantList: GrantType[] | null;
  isGrantListLoading: boolean;
  grant: GrantType | null;
  isGrantLoading: boolean;
  isGrantActionInProgress: boolean;
};

type FundingState = LoanState & GrantState;

const loanInitialState: LoanState = {
  loanList: null,
  isLoanListLoading: true,
  loan: null,
  isLoanLoading: true,
  isLoanActionInProgress: false,
};

const grantInitialState: GrantState = {
  grantList: null,
  isGrantListLoading: true,
  grant: null,
  isGrantLoading: true,
  isGrantActionInProgress: false,
};

const fundingInitialState: FundingState = {
  ...loanInitialState,
  ...grantInitialState,
};

const fundingSlice = createSlice({
  name: 'funding',
  initialState: fundingInitialState,
  reducers: {
    resetLoanState: (state) => ({
      ...state,
      ...loanInitialState,
    }),
    resetGrantState: (state) => ({
      ...state,
      ...grantInitialState,
    }),
    resetState: () => fundingInitialState,
  },
  extraReducers: (builder) => {
    // Loan
    builder.addCase(fetchGetTenantLoanList.pending, (state) => {
      state.isLoanListLoading = true;
    });
    builder.addCase(fetchGetTenantLoanList.fulfilled, (state, action) => {
      state.loanList = action.payload;
      state.isLoanListLoading = false;
    });
    builder.addCase(fetchGetTenantLoanList.rejected, (state) => {
      state.isLoanListLoading = false;
    });
    builder.addCase(fetchGetLoan.pending, (state) => {
      state.isLoanLoading = true;
    });
    builder.addCase(fetchGetLoan.fulfilled, (state, action) => {
      state.loan = action.payload;
      state.isLoanLoading = false;
    });
    builder.addCase(fetchGetLoan.rejected, (state) => {
      state.isLoanLoading = false;
    });
    builder.addCase(fetchCreateLoan.pending, (state) => {
      state.isLoanActionInProgress = true;
    });
    builder.addCase(fetchCreateLoan.fulfilled, (state, action) => {
      state.isLoanActionInProgress = false;
      state.loan = action.payload;
    });
    builder.addCase(fetchCreateLoan.rejected, (state) => {
      state.isLoanActionInProgress = false;
    });
    builder.addCase(fetchUpdateLoan.pending, (state) => {
      state.isLoanActionInProgress = true;
    });
    builder.addCase(fetchUpdateLoan.fulfilled, (state, action) => {
      state.isLoanActionInProgress = false;
      state.loan = action.payload;
    });
    builder.addCase(fetchUpdateLoan.rejected, (state) => {
      state.isLoanActionInProgress = false;
    });
    builder.addCase(fetchDeleteLoan.pending, (state) => {
      state.isLoanActionInProgress = true;
    });
    builder.addCase(fetchDeleteLoan.fulfilled, (state, action) => {
      state.isLoanActionInProgress = false;
      state.loanList = (state.loanList || []).filter(
        (loan) => loan.id !== action.payload,
      );
    });
    builder.addCase(fetchDeleteLoan.rejected, (state) => {
      state.isLoanActionInProgress = false;
    });

    // Grant
    builder.addCase(fetchGetTenantGrantList.pending, (state) => {
      state.isGrantListLoading = true;
    });
    builder.addCase(fetchGetTenantGrantList.fulfilled, (state, action) => {
      state.grantList = action.payload;
      state.isGrantListLoading = false;
    });
    builder.addCase(fetchGetTenantGrantList.rejected, (state) => {
      state.isGrantListLoading = false;
    });
    builder.addCase(fetchGetGrant.pending, (state) => {
      state.isGrantLoading = true;
    });
    builder.addCase(fetchGetGrant.fulfilled, (state, action) => {
      state.grant = action.payload;
      state.isGrantLoading = false;
    });
    builder.addCase(fetchGetGrant.rejected, (state) => {
      state.isGrantLoading = false;
    });
    builder.addCase(fetchCreateGrant.pending, (state) => {
      state.isGrantActionInProgress = true;
    });
    builder.addCase(fetchCreateGrant.fulfilled, (state, action) => {
      state.isGrantActionInProgress = false;
      state.grant = action.payload;
    });
    builder.addCase(fetchCreateGrant.rejected, (state) => {
      state.isGrantActionInProgress = false;
    });
    builder.addCase(fetchUpdateGrant.pending, (state) => {
      state.isGrantActionInProgress = true;
    });
    builder.addCase(fetchUpdateGrant.fulfilled, (state, action) => {
      state.isGrantActionInProgress = false;
      state.grant = action.payload;
    });
    builder.addCase(fetchUpdateGrant.rejected, (state) => {
      state.isGrantActionInProgress = false;
    });
    builder.addCase(fetchDeleteGrant.pending, (state) => {
      state.isGrantActionInProgress = true;
    });
    builder.addCase(fetchDeleteGrant.fulfilled, (state, action) => {
      state.isGrantActionInProgress = false;
      state.grantList = (state.grantList || []).filter(
        (grant) => grant.id !== action.payload,
      );
    });
    builder.addCase(fetchDeleteGrant.rejected, (state) => {
      state.isGrantActionInProgress = false;
    });
  },
});

export const { resetLoanState, resetGrantState, resetState } =
  fundingSlice.actions;

export default fundingSlice;
