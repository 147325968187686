import { createSlice } from '@reduxjs/toolkit';
import { PartnerResourcesDto } from '_types/partner-resources-types';

import {
  fetchCreatePartnerResource,
  fetchDeletePartnerResource,
  fetchGetPartnerResource,
  fetchGetPartnerResourcesList,
  fetchUpdatePartnerResource,
} from 'store/actions/partner-resources';

interface PartnerResourcesState {
  partnerResource: {
    data: PartnerResourcesDto | null;
    isLoading: boolean;
    isUploading: boolean;
  };
  partnerResourceList: {
    data: PartnerResourcesDto[] | null;
    isLoading: boolean;
  };
}

const partnerResourcesInitialState: PartnerResourcesState = {
  partnerResource: {
    data: null,
    isLoading: false,
    isUploading: false,
  },
  partnerResourceList: {
    data: null,
    isLoading: false,
  },
};

const partenerResourcesSlice = createSlice({
  name: 'partnerResource',
  initialState: partnerResourcesInitialState,
  reducers: {
    resetState: () => partnerResourcesInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetPartnerResourcesList.pending, (state) => {
      state.partnerResourceList.isLoading = true;
    });
    builder.addCase(fetchGetPartnerResourcesList.fulfilled, (state, action) => {
      state.partnerResourceList.data = action.payload;
      state.partnerResourceList.isLoading = false;
    });
    builder.addCase(fetchGetPartnerResourcesList.rejected, (state) => {
      state.partnerResourceList.isLoading = false;
    });
    builder.addCase(fetchGetPartnerResource.pending, (state) => {
      state.partnerResource.isLoading = true;
    });
    builder.addCase(fetchGetPartnerResource.fulfilled, (state, action) => {
      state.partnerResource.data = action.payload;
      state.partnerResource.isLoading = false;
    });
    builder.addCase(fetchGetPartnerResource.rejected, (state) => {
      state.partnerResource.isLoading = false;
    });
    builder.addCase(fetchCreatePartnerResource.pending, (state) => {
      state.partnerResource.isUploading = true;
    });
    builder.addCase(fetchCreatePartnerResource.fulfilled, (state, action) => {
      state.partnerResource.data = action.payload;
      state.partnerResource.isUploading = false;
    });
    builder.addCase(fetchCreatePartnerResource.rejected, (state) => {
      state.partnerResource.isUploading = false;
    });
    builder.addCase(fetchUpdatePartnerResource.pending, (state) => {
      state.partnerResource.isUploading = true;
    });
    builder.addCase(fetchUpdatePartnerResource.fulfilled, (state, action) => {
      state.partnerResource.data = action.payload;
      state.partnerResource.isUploading = false;
    });
    builder.addCase(fetchUpdatePartnerResource.rejected, (state) => {
      state.partnerResource.isUploading = false;
    });
    builder.addCase(fetchDeletePartnerResource.pending, (state) => {
      state.partnerResource.isUploading = true;
    });
    builder.addCase(fetchDeletePartnerResource.fulfilled, (state, action) => {
      state.partnerResource.isUploading = false;
      state.partnerResourceList.data = (
        state.partnerResourceList.data || []
      ).filter((pr) => pr.id !== action.payload);
    });
    builder.addCase(fetchDeletePartnerResource.rejected, (state) => {
      state.partnerResource.isUploading = false;
    });
  },
});

export const { resetState } = partenerResourcesSlice.actions;

export default partenerResourcesSlice;
