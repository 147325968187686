import React, { memo } from 'react';
import clsx from 'clsx';

import Loader from '../Loader';

import './style.scss';

export type ButtonProps = {
  look: 'filled' | 'text-with-underline' | 'unchanged';
  isLoading?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  value?: React.ButtonHTMLAttributes<HTMLButtonElement>['value'];
  onClick?: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  className?: React.ButtonHTMLAttributes<HTMLButtonElement>['className'];
};

const Button: React.FC<ButtonProps> = memo(
  ({
    look,
    children,
    type,
    className,
    disabled,
    leftIcon,
    rightIcon,
    value,
    onClick,
    isLoading,
  }) => (
    <button
      type={type} // eslint-disable-line react/button-has-type
      disabled={disabled}
      aria-disabled={disabled}
      value={value}
      onClick={onClick}
      className={clsx('button', className, look, {
        disabled: disabled || isLoading,
        loading: isLoading,
      })}
    >
      {look === 'unchanged' ? (
        children || value
      ) : (
        <>
          {leftIcon && <div className="icon_left">{leftIcon}</div>}

          {(children || value) && (
            <div className="children">{children || value}</div>
          )}

          {rightIcon && <div className="icon_right">{rightIcon}</div>}

          {isLoading && (
            <Loader
              withContainer={false}
              withText={false}
              size="small"
              color={look === 'filled' ? 'white' : 'main-blue'}
            />
          )}
        </>
      )}
    </button>
  ),
);

export default Button;
