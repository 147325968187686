export enum TenantTypeEnum {
  NXST = 'nxst',
  NYC_FUNDS_FINDER = 'nyc_funds_finder',
  VERIZON = 'verizon',
  MICHIGAN = 'michigan',
  LOS_ANGELES = 'los_angeles',
  SUPPLIER_RESOURCE_HUB = 'supplier_resource_hub',
}

export type Tenant = {
  id: number;
  name: string;
  domain: string;
  logo?: string;
  type: TenantTypeEnum;
};
export interface TenantStyle {
  id: number;
  logo: string;
  picture: string;
  mainColor: string;
  secondaryColor: string;
  shades08: string;
  shades09: string;
  shades10: string;
  shades12: string;
  secondaryTeal: string;
  secondary04: string;
  secondary05: string;
  secondary06: string;
  secondary08: string;
  mainYellow: string;
  font1: string;
  font2: string;
  colorVariables: ColorVariables;
}

export interface ColorVariables {
  background: {
    background_primary_light: string;
    background_primary_dark: string;
    background_secondary: string;
    background_section: string;
    background_avatar_with_letters: string;
    background_avatar_organization: string;
    background_glossary: string;
  };
  borders: {
    border_cards: string;
    border_dropdown_filters: string;
    border_tetriary_button: string;
    border_search_field: string;
    border_avatar: string;
    border_funding: string;
    border_field_hover: string;
  };
  buttons: {
    button_primary1_active: string;
    button_primary2_active: string;
    button_secondary: string;
    button_tetriary_active: string;
    button_pagination_selected: string;
    button_pagination_unselected: string;
    button_notification_selected: string;
    button_notification_unselected: string;
    button_radio_button_check_box: string;
  };
  error_alert: {
    error_alert: string;
  };
  icons: {
    icon_primary2_button: string;
    icon_secondary_tetriary_button: string;
    icon_dropdown: string;
    icon_pagination: string;
    icon_notification_unselected: string;
    icon_notification_hover: string;
    icon_notification_selected: string;
    icon_secondary: string;
  };
  lines: {
    line_section: string;
    line_footer: string;
    line_section_title_scroll: string;
    line_header: string;
  };
  switcher: {
    switcher_selected: string;
    switcher_unselcted: string;
  };
  tags: {
    tag_primary_background: string;
    tag_secondary_background: string;
  };
  text: {
    text_buttons: string;
    text_primary1: string;
    text_primary2: string;
    text_secondary: string;
    text_accent_1: string;
    text_accent_2: string;
    text_secondary_buttons: string;
    text_on_the_dark_background: string;
    text_search_field_default: string;
    text_priamry1_lighten: string;
  };
}

export type TenantStyleDetails = TenantStyle & Tenant;

export interface TenantFeature {
  id: number;
  tenantId: number;
  featureName: string;
  isActive: boolean;
}

export type BusinessTopicDataType = {
  value: string;
  iconKey: string;
  titleTranslationKey: string;
  hintTranslationKey: string | null;
};

export interface TenantSettings {
  sboBusinessNeedsAvailable: BusinessTopicDataType[];

  advisorSkillsAvailable: BusinessTopicDataType[];
}
