import React, { memo } from 'react';
import { Route, Routes } from 'react-router-dom';

import NotFoundPage from 'utils/common-components/NotFoundPage';

const ProfileLayout = React.lazy(() => import('./layout'));
const ProfilePage = React.lazy(() => import('./ProfilePage'));

const ProfileRoutes: React.FC = memo(() => (
  <Routes>
    <Route element={<ProfileLayout />}>
      <Route index element={<ProfilePage />} />
    </Route>

    <Route path="*" element={<NotFoundPage />} />
  </Routes>
));

export default ProfileRoutes;
